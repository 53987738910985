@import "styles/common";

.tabs {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem 2.5rem;

  &__item {
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: $dark-gray;

    &_accessible {
      color: $content;
    }

    &_link {
      position: relative;
      display: block;
      padding: 0 0 0.875rem;
      text-decoration: none;
      white-space: nowrap;
      letter-spacing: 0;

      &:hover {
        text-decoration: none;
        color: $violet-dark;
      }

      &::after {
        position: absolute;
        bottom: -1px;
        content: "";
        display: block;
        width: 100%;
        height: 0.25rem;
        border-bottom: 0;
      }
    }

    &_active {
      font-weight: bold;
      color: $violet-dark;

      &:hover {
        color: $violet-dark;
      }

      &::after {
        background: $violet-dark;
      }
    }
  }

  &_lk {
    align-items: flex-end;
    height: 12.5rem;
    margin-bottom: 3.125rem;
    background: $graphite;

    &_accessible {
      background: $white;
    }

    &__item {
      margin-right: 40px;
      font-family: $font-family-heading;

      &_link {
        &:hover {
          background: $gradient;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &_linkAccessible {
        &:hover {
          color: $content;
        }
      }

      &_active {
        background: $gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::after {
          background: $gradient;
        }
      }

      &_activeAccessible {
        color: $content;

        &::after {
          background: $content;
        }
      }
    }
  }

  &_window {
    flex-wrap: wrap-reverse;
    border-bottom: solid 1px $btn-outline;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
